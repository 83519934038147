<template>
  <div id="app">
    <vs-navbar shadow fixed>
      <template #left>
        <img src="../public/via.svg" style="height: 24px">
      </template>
      <template #right>
        <a href="https://micamd.com" target="_blank">
          <img src="../public/mica.svg" style="height: 12px">
        </a>
      </template>
    </vs-navbar>
    <div class="query">
      <div class="jumbotron">
        <vs-alert closable v-model="showAlert">
          For additional information and latest updates, please visit the <a href="https://doh.gov.ph/vaccines" target="_blank">Department of Health website</a>
        </vs-alert>
        <h1>Your COVID-19 vaccine questions answered</h1>
        <p class="desc">Search the Department of Health COVID-19 Vaccine FAQs, powered by AI-driven enterprise search and text analytics</p>
      </div>
      <div class="searchbar grid">
        <vs-row>
          <vs-col w="9">
            <vs-input placeholder="Enter search term" v-model="query"></vs-input>
          </vs-col>
          <vs-col w="3">
            <vs-button ref="searchBtn" @click="search" :disabled="query === ''">Search</vs-button>
          </vs-col>
        </vs-row>
      </div>
      <div class="examples" ref="examples" v-if="!results">
        <div class="example-query" v-for="example in exampleQueries" :key="example">
          <p @click="exampleQuery(example)">{{example}}</p>
        </div>
      </div>
    </div>
    <div class="results" v-if="results">
      <p style="text-align: center">{{results.length}} Results Found</p>
      <vs-row justify="center">
        <div class="result-container" v-for="result in results" :key="result.question">
          <vs-card>
            <template #title>
              <h3>{{ result.q }}</h3>
            </template>
            <template #text>
              <div class="answer">{{ result.a }}</div>
            </template>
          </vs-card>
        </div>
      </vs-row>
    </div>
    <div class="footer">
      <p>DISCLAIMER: Use of this service is for informational purposes only and is not a qualified medical opinion.</p>
      <p>Information sourced from the Department of Health website: doh.gov.ph. Please visit the DOH website to get the latest information.</p>
      <p>
        <a href="https://drive.google.com/open?id=16bx1eM8n1Eyyb06Sd4JLRl_FywQxi1ol" target="_blank">Privacy Policy</a> | <a href="https://drive.google.com/open?id=16ctFTh6ggV9NkaMRc_idSWUXyIFyC5nZ" target="_blank">Terms of Service</a>
      </p>
      <p>MICA HEALTH DATA PRIVACY ACT OF 2012 (R.A. 10137) COMPLIANT</p>
      <p>© SMP and Co Partners, Inc. All rights reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data:() => ({
    query: '',
    results: null,
    isLoading: false,
    showAlert: true,
    exampleQueries: [
        "Is it safe?",
        "Vaccination during pregnancy",
        "Vaccine efficacy",
        "How old do I have to be to get a vaccine?",
        "Are medical students considered A1?",
        "Can I catch COVID from the vaccine?",
        "Is vaccination free?",
        "Who are in group A4"
    ]
  }),
  methods: {
    search: async function() {
      const loading = this.$vs.loading({ target: this.$refs.searchBtn, type: "points" })
      let query = encodeURI(this.query)
      let url = `https://vaccine-information-assistant-bot.search.windows.net/indexes/qna/docs?api-version=2021-04-30-Preview&search=${query}`
      try {
        let config = {
          headers: {
            'Content-Type': 'application/json',
            'api-key': 'BC95E993756BA22DE1EE09C12B033B2A'
          }
        }
        let call = await this.axios.get(url, config)
        this.results = call.data.value
        loading.close()
      } catch (e) {
        loading.close()
        alert(e)
      }
    },
    exampleQuery: async function(example) {
      const loading = this.$vs.loading({ target: this.$refs.examples })
      this.query = example
      await this.search()
      loading.close()
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Montserrat:wght@500&display=swap');

body {
  font-family: "IBM Plex Sans", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
}

#app {
  max-width: 100vw;
  overflow-x: hidden;
}

.query {
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 54px;
  padding-bottom: 42px;
}

.desc {
  font-size: 15px;
  color: #2F2E41;
}

.vs-input {
  width: 100% !important;
  font-size: 24px;
}

.vs-button {
  width: 90% !important;
  height: 36px !important;
}

.examples {
  font-size: 12px;
  padding: 18px;
  padding-top: 24px;
  color: #7e7e7e;
}

.example-query {
  padding-bottom: 4px;
  cursor: pointer;
}

.results {
  padding-top: 24px;
  padding-bottom: 24px;
  background: #f0f8ff;
  border-radius: 14px;
}

.result-container {
  padding: 8px;
}

.answer {
  white-space: pre-line;
}

.footer {
  text-align: center;
  color: #4a4a4a;
  padding: 18px;
  font-size: 10px;
  margin-top: 12px;
  border-radius: 14px;
  background: #fafafa;
}

@media screen and (max-width: 600px) {
  .query {
    padding: 0;
    padding-bottom: 16px;
    margin-top: 54px;
  }

  .jumbotron {
    padding-left: 12px;
    padding-right: 12px;
  }

  .results {
    padding: 8px
  }
}
</style>
